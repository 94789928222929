<template>
    <!-- Footer Start -->
    <div class="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-3 col-md-6">
                    <h1 class="fw-bold text-green mb-4">N<span class="text-orange">ongsan</span>shop</h1>
                    <p>{{ $filters.capitalize($t('title.social-links')) }}</p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.instagram"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-0" :href="contact_us.tiktok"><i class="fab fa-tiktok"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">{{ $filters.capitalize($t('title.address')) }}</h4>
                    <p><i class="fa fa-map-marker-alt me-3"></i>{{ contact_us.address }}</p>
                    <p><i class="fa fa-phone-alt me-3"></i>{{ contact_us.phone }}</p>
                    <p><i class="fa fa-envelope me-3"></i>{{ contact_us.email }}</p>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">{{ $filters.capitalize($t('title.links')) }}</h4>
                    <a class="btn btn-link" href="/gioi-thieu">{{ $filters.capitalize($t('title.about-us')) }}</a>
                    <a class="btn btn-link" href="/lien-he">{{ $filters.capitalize($t('title.contact-us')) }}</a>
                    <a class="btn btn-link" href="/san-pham">{{ $filters.capitalize($t('title.product')) }}</a>
                    <a class="btn btn-link" href="/policy">{{ $filters.capitalize($t('title.policy')) }}</a>
                    <a class="btn btn-link" href="/ho-tro">{{ $filters.capitalize($t('title.support')) }}</a>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">{{ $filters.capitalize($t('title.receive-news')) }}</h4>
                    <p>{{ $filters.capitalize($t('title.register-to-receive-hot-news')) }}</p>
                    <div class="position-relative mx-auto" style="max-width: 400px;">
                        <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5 form-control-sm" type="text" placeholder="Địa chỉ email">
                        <button type="button" class="btn btn-green py-2 position-absolute top-0 end-0 mt-2 me-2 btn-sm">{{ $filters.capitalize($t('title.register')) }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid copyright">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a href="#">Blockais</a>, All Right Reserved.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                        Thiết kế bởi <a href="https://blockais.com">Blockais</a>
                        <br>Phân phối bởi: <a href="https://blockais.com" target="_blank">Blockais</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->
    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-green btn-lg-square rounded-circle back-to-top"><i class="bi bi-arrow-up"></i></a>
</template>
<script>
// import { website_info } from '@/mockupdata/website_info';
export default {
    name: "FooterVue",
    props: {
        contact_us: {
            type: Object,
            default() {
                return {}
            }
        }
    }
};
</script>