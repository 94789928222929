import { createRouter, createWebHistory } from "vue-router"
import store from "@/store";
const HomePage = () => import("../views/HomePage.vue")
const ProductIndex = () => import("../views/product/Index.vue")
const ProductCategoryIndex = () => import("../views/product/IndexCategory.vue")
const ProductDetail = () => import("../views/product/Detail.vue")
const SellingFieldIndex = () => import("../views/field/Index.vue")
const SellingFieldCategoryIndex = () => import("../views/field/IndexCategory.vue")
const SellingFieldDetail = () => import("../views/field/Detail.vue")
const FieldBuyOfferIndex = () => import("../views/fieldbuyoffer/Index.vue")
const FieldBuyOfferDetail = () => import("../views/fieldbuyoffer/Detail.vue")
const FieldBuyOfferCreate = () => import("../views/fieldbuyoffer/Create.vue")
const FieldSalesContractIndex = () => import("../views/salecontract/Index.vue")
const FieldSalesContractDetail = () => import("../views/salecontract/Detail.vue")
const NewsIndex = () => import("../views/news/Index.vue")
const NewsCategoryIndex = () => import("../views/news/IndexCategory.vue")
const NewsDetail = () => import("../views/news/Detail.vue")
const ShortVideoIndex = () => import("../views/shortvideo/Index.vue")
const ShortVideoCategoryIndex = () => import("../views/shortvideo/IndexCategory.vue")
const ShortVideoDetail = () => import("../views/shortvideo/Detail.vue")
const CartIndex = () => import("../views/cart/index.vue")
const ContactUs = () => import("../views/ContactUs.vue")
const AboutPage = () => import("../views/AboutPage.vue")
const Page404 = () => import("../views/404Page.vue")
const FeaturePage = () => import("../views/FeaturePage.vue")
const LoginPage= () => import('../views/LoginPage.vue')
const RegisterPage= () => import('../views/RegisterPage.vue')
const VerifyPage= () => import('../views/VerifyPage.vue')
const BookingCreate= () => import('../views/booking/create.vue')
const BookService= () => import('../views/booking/BookService.vue')
const BookCarSale= () => import('../views/booking/BookCarSale.vue')
const PorfileIndex = () => import("../views/profile/index.vue")
const OrderIndex = () => import("../views/order/Index.vue")
const OrderDetail = () => import("../views/order/Detail.vue") 
const ServiceRequestIndex = () => import("../views/servicerequest/Index.vue")
const ServiceRequestDetail = () => import("../views/servicerequest/Detail.vue") 
const Page403= () => import('../views/403Page.vue')
const ShopIndex = () => import("../views/shop/Index.vue")
const ShopCategoryIndex = () => import("../views/shop/IndexCategory.vue")
const ShopDetail = () => import("../views/shop/Detail.vue")
const GoogleReturn = () => import("@/views/GoogleReturn.vue")
const PriceQuotationIndex = () => import("../views/pricequotation/Index.vue")
const PriceQuotationCategoryIndex = () => import("../views/pricequotation/IndexCategory.vue")
const PriceQuotationDetail = () => import("../views/pricequotation/Detail.vue")
const DetectIndex = () => import("../views/dcf/Index.vue")
// const { list } from "@/api/permission"
const routes = [
    {
      path: "/",
      name: "Home",
      component: HomePage
    },
    {
        path: "/san-pham/:slug",
        name: "SanPhamCategory",
        component: ProductCategoryIndex
    },
    {
        path: "/san-pham",
        name: "SanPham",
        component: ProductIndex
    },
    {
        path: "/san-pham/detail/:slug",
        name: "DetailSanPham",
        component: ProductDetail
    },
    {
        path: "/selling-field/:id",
        name: "SellingFieldCategoryIndex",
        component: SellingFieldCategoryIndex
    },
    {
        path: "/selling-field",
        name: "SellingFieldIndex",
        component: SellingFieldIndex
    },
    {
        path: "/selling-field/chi-tiet/:id",
        name: "SellingFieldDetail",
        component: SellingFieldDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/field-buy-offer",
        name: "FieldBuyOfferIndex",
        component: FieldBuyOfferIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/field-buy-offer/:id",
        name: "FieldBuyOfferDetail",
        component: FieldBuyOfferDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/field-buy-offer/create/:id",
        name: "FieldBuyOfferCreate",
        component: FieldBuyOfferCreate,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/field-sales-contract",
        name: "FieldSalesContractIndex",
        component: FieldSalesContractIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/field-sales-contract/:id",
        name: "FieldSalesContractDetail",
        component: FieldSalesContractDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/shop",
        name: "ShopIndex",
        component: ShopIndex
    },
    {
        path: "/shop/:slug",
        name: "ShopCategoryIndex",
        component: ShopCategoryIndex
    },
    {
        path: "/shop/chi-tiet/:slug",
        name: "ShopDetail",
        component: ShopDetail
    },
    {
        path: "/blog",
        name: "TinTuc",
        component: NewsIndex
    },
    {
        path: "/blog/:slug",
        name: "TinTucCategory",
        component: NewsCategoryIndex
    },
    {
        path: "/blog/chi-tiet/:slug",
        name: "DetailTinTuc",
        component: NewsDetail
    },
    {
        path: "/short-video",
        name: "ShortVideo",
        component: ShortVideoIndex
    },
    {
        path: "/short-video/:slug",
        name: "ShortVideoCategory",
        component: ShortVideoCategoryIndex
    },
    {
        path: "/short-video/chi-tiet/:slug",
        name: "DetailShortVideo",
        component: ShortVideoDetail
    },
    {
        path: "/lien-he",
        name: "LienHe",
        component: ContactUs
    },
    {
        path: "/gioi-thieu",
        name: "GioiThieu",
        component: AboutPage
    },
    {
        path: "/cart",
        name: "CartIndex",
        component: CartIndex
    },
    {
        path: "/booking",
        name: "BookingCreate",
        component: BookingCreate
    },
    {
        path: "/book-service/:id",
        name: "BookService",
        component: BookService
    },
    {
        path: "/book-car-sale/:id",
        name: "BookCarSale",
        component: BookCarSale
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage
    },
    {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage
    },
    {
        path: "/emaiverify/:id/:hash",
        name: "EmailVerify",
        component: VerifyPage
    },
    {
        path: "/404",
        name: "page404",
        component: Page404,
        meta: {requiresAuth: false}
    },
    {
        path: "/403",
        name: "page403",
        component: Page403,
        meta: {requiresAuth: false}
    },
    {
        path: "/dac-diem",
        name: "page404",
        component: FeaturePage,
        meta: {requiresAuth: false}
    },
    {
        path: "/manager/profile",
        name: "ProfileIndex",
        component: PorfileIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/order",
        name: "OrderIndex",
        component: OrderIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/service-request",
        name: "ServiceRequestIndex",
        component: ServiceRequestIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/order/:id",
        name: "OrderDetail",
        component: OrderDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/service-request/:id",
        name: "ServiceRequestDetail",
        component: ServiceRequestDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/detect",
        name: "DetectIndex",
        component: DetectIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/google/return",
        name: "GoogleReturn",
        component: GoogleReturn,
        meta: {requiresAuth: false}
    },
    {
        path: "/price-quotation/:id",
        name: "PriceQuotationCategoryIndex",
        component: PriceQuotationCategoryIndex
    },
    {
        path: "/price-quotation",
        name: "PriceQuotationIndex",
        component: PriceQuotationIndex
    },
    {
        path: "/price-quotation/chi-tiet/:id",
        name: "PriceQuotationDetail",
        component: PriceQuotationDetail
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
  });
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        console.log(store.getters.user);
        if (store.state.islogin) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});
//   router.beforeEach(async (to, from, next) => {
//     if (to.matched.some((record) => record.meta != null &&  record.meta.requiresAuth)) {
//         let res = await list();
//         if (res != null && res.status == 200 && res.data != null && res.data.includes(to.meta.permission)) {
//             next();
//             return;
//         } else if (to.meta.permission == null) {
//             next();
//             return;
//         }
//         next('403');
//     } else {
//       next();
//     }
//   });
  export default router;